import React from "react";

import { BACKEND_BASE_URL } from "../../constant/constants";

import Layout from "../../components/layout";
import { Seo } from "../../components";
import PublicationDetail from "../../container/publication-detail";

const PublicationDetailPage = arg => {
  return (
    <Layout location={arg.location}>
      <Seo title="Publication Detail" />
      <PublicationDetail
        id={arg.uuid}
        data={arg.serverData.publicaiton_info}
        yearList={arg.serverData.list_info}
      />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const publication_list_info_res = await fetch(
      BACKEND_BASE_URL + "publications"
    );

    const publication_info_res = await fetch(
      BACKEND_BASE_URL + "publications/" + context.params.uuid
    );

    if (!publication_list_info_res.ok) {
      throw new Error(`Response failed`);
    }
    if (!publication_info_res.ok) {
      throw new Error(`Response failed`);
    }
    return {
      props: {
        list_info: await publication_list_info_res.json(),
        publicaiton_info: await publication_info_res.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default PublicationDetailPage;
