import React from "react";

import { NewsEventSlider } from "../components";
import Publications from "./publications";

const PublicationDetail = props => {
  const { id, data, yearList } = props;

  return (
    <div className="about">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Publications selected={id} data={data} yearList={yearList} />
            <NewsEventSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationDetail;
